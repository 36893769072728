import { defineStore } from 'pinia';
import { isNil } from 'ramda';

export const useOrganisationStore = defineStore('organisation', {
    state: (): OrganisationStore => ({
        users: [],
    }),
    getters: {
        getUsers: (state: OrganisationStore): { label: string; key: number }[] => {
            return state.users.map((user: OrganisationUser) => ({
                label: `${user.firstName} ${user.lastName}`,
                key: user.id,
            }));
        },
    },
    actions: {
        async loadUsers(organisationId: number | null | undefined) {
            const { OrganisationAPI } = useApi();
            if (!isNil(organisationId)) {
                try {
                    this.users = await OrganisationAPI.getUsers(organisationId);
                    return;
                } catch {
                    console.error('could not load users');
                }
            }
            this.users = [];
        },
    },
});
